export default [
    {
        path: process.env.VUE_APP_FRONT_URL_RH,
        redirect: process.env.VUE_APP_FRONT_URL_RH + '/vagas',
    },
    {
        name: 'Homepage',
        path: process.env.VUE_APP_FRONT_URL_RH + '/vagas',
        component: () => import('../views/Listar')
    },
    {
        name: 'Vagas',
        path: process.env.VUE_APP_FRONT_URL_RH,
        component: () => import('../views/Listar')
    },
    {
        name: 'visualizarVagaPublica',
        path: process.env.VUE_APP_FRONT_URL_RH + '/vaga/:id',
        component: () => import('../views/Visualizar.vue')
    },
]