import api from '../api'
import store from '@/shared/store'

const endpoint = process.env.VUE_APP_API_URL_RH;

store.registerModule('recursos_humanos_portal', {
    namespaced: true,
    state: {
        loja: null,
        candidato: null,
        candidaturas: [],
    },
    getters: {
        loja (state) {
            return state.loja
        },
        candidaturas (state) {
            return state.candidaturas
        },

    },
    mutations: {
        atualizaLoja (state, loja){
            state.loja = loja
        },
        atualizaCandidato (state, candidato){
            state.candidato = candidato
        },
        atualizaCurriculo (state, curriculo){
            state.candidato.curriculo = curriculo
        },
        atualizaCandidaturas (state, candidaturas){
            state.candidaturas = candidaturas
        },
    },
    actions: {
        async carregaDadosLoja ({ commit }){
            try {
                const retorno = await api.get(endpoint + `loja`)
                if (retorno.status === 200) {
                    commit('atualizaLoja', retorno.data)
                }
            } catch (error) {
                // console.error(error);
                this.$notify.error('Não foi possível carregar a página.')
            }
        },
        async carregaCandidato ({ commit }){
            try {
                const retorno = await api.get(`${endpoint}autenticar`)
                if (retorno.status === 200) {
                    commit('atualizaCandidato', retorno.data)
                }
            } catch (error) {
                this.$notify.error('Não foi possível carregar o candidato.')
            }
        },
        async carregaCandidaturas ({ commit }){
            try {
                const retorno = await api.get(endpoint + `candidatos/candidaturas`)
                if (retorno.status === 200) {
                    commit('atualizaCandidaturas', retorno.data.candidaturas)
                }
                return retorno
            } catch (error) {
                this.$notify.error('Não foi possível carregar as candidaturas.')
            }
        },
        atualizaCurriculo({ commit }, curriculo ) {
            commit('atualizaCurriculo', curriculo)
        },
        async logout ({ commit }) {
            const retorno = await api.get(endpoint + `logout`)
            if (retorno.status === 200) {
                commit('atualizaCandidato', null)
            }
            return retorno.status === 200
        }
    }
})

export default store
