import Vue from 'vue'
import axios from 'axios'
import '@/shared/plugins/recaptcha'

const headers = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Credentials': true
}

const instance = axios.create({
    headers,
    withCredentials: true,
    baseURL: process.env.VUE_APP_API_URL_RH
});

const api = {
    recaptcha: false,

    async request(url, params = {}, method = 'get')
    {
        try {
            if (this.recaptcha){
                if (await Vue.prototype.$recaptchaLoaded()) {
                    Vue.prototype.$recaptchaInstance.showBadge()
                }
                const recaptcha = await Vue.prototype.$recaptcha('login')
                Object.assign(params, {
                    'g-recaptcha-response': recaptcha
                })
            }

            return await instance.request({
                url, method, data: params
            })
        } catch (error) {
            if (error.response) {
                return error.response
            } else if (error.request) {
                return error.request
            }
            return error
        }
    },

    async get (url, params = {}) {
        return await this.request(url, { params: params }, 'get')
    },

    async post (url, params = {}) {
        return await this.request(url, params, 'post')
    },

    async put (url, params = {}) {
        return await this.request(url, params, 'put')
    },

    async delete (url, params = {}) {
        return await this.request(url, params, 'delete')
    }
}

export default api


