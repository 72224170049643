import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
    // routes,
    mode: 'history',
    // base: process.env.BASE_URL,
    scrollBehavior() {
        document.getElementById('app').scrollIntoView({
            behavior: "smooth"
        });
    }
})

let childrenRoutes = []
const paths = require.context('../domains/', true, /routes.js$/i)
const context = paths.keys().map(paths).map((m) => m.default)

context.map((arr) => {
    if (arr) {
        arr.map((val) => {
            childrenRoutes.push(val)
        })
    }
})

router.addRoute({
    meta: { public: true },
    path: '/' + process.env.VUE_APP_FRONT_URL_RH,
    component: () => import('../App'),
    children: childrenRoutes
})

router.addRoute({
    redirect: '/inicio',
    path: `/${process.env.VUE_APP_FRONT_URL_RH}/sistema/acessar_erp`,
})

export default router
