import Vue from 'vue'
import '@/shared/plugins'
import App from '@/App.vue'
import router from './router'
import api from './shared/api'
import '@/shared/common/filters'
import store from './shared/store'
// import '@/shared/layouts/adminlte'
// import '@/shared/assets/css/main.scss'
import './shared/assets/portal.css'

Vue.prototype.$api = api
Vue.prototype.$urlBase = process.env.VUE_APP_FRONT_URL_RH

new Vue({
    store,
    router,
    provide: {
        endpoint: process.env.VUE_APP_API_URL_RH
    },
    render: (h) => h(App),
}).$mount('#app')

